<template>
  <div class="organization">
    <div class="bread">
      <i class="el-icon-arrow-left" @click="goback()"></i>
      组织机构
    </div>
    <div class="head"></div>
    <div class="content">
      <div class="box">
        <h3>主办单位：</h3>
        <p>北京社区健康促进会，北京医师协会全科医师分会</p>
      </div>
      <div class="box">
        <h3>协办单位：</h3>
        <p>国医延长（北京）医学技术服务有限公司</p>
      </div>
      <div class="box">
        <h3>参会人员：</h3>
        <p>
          北京市卫健委基层司相关领导、16区卫健委、社管中心、北京社区健康促进会和北京医师协会全科医师分会会员；北京市基层医疗卫生服务机构管理者、全科医生或家医团队；北京市全科医学领域专家、学者；卫生健康行政人员及特邀嘉宾。
        </p>
      </div>
      <div class="box">
        <h3>会议时间：</h3>
        <p>2023年3月29日（周三），会议时长1天。</p>
      </div>
      <div class="box">
        <h3>会议地点：</h3>
        <p>北京朗丽兹西山花园酒店 (海淀区丰智东路13号)</p>
      </div>
    </div>
    <div class="bg3"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isheight: false,
    };
  },
  created() {},
  mounted() {
    if (document.documentElement.clientHeight < 700) {
      const objects = document.getElementsByClassName("box");
      for (var obj of objects) {
        obj.style.paddingTop = "15px";
        obj.style.fontSize = "13px";
      }
    }
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.organization {
  color: #fff;
  background: url("../../assets/image2/background.png") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  position: relative;
  .bread {
    height: 50px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    position: relative;
    i {
      position: absolute;
      left: 10px;
      top: 17px;
      font-weight: 600;
    }
  }
  .head {
    height: 95px;
    background: url("../../assets/image2/head.png") no-repeat;
    background-size: 100% 100%;
  }
  .content {
    z-index: 999;
    padding: 0 20px 0 38px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    .box {
      padding-top: 35px;
      p {
        padding-left: 13px;
        font-weight: 300;
      }
      .pdtop {
        padding-top: 20px;
      }
      h3 {
        padding-bottom: 15px;
        font-weight: 600;
      }
    }
  }
  .bg3 {
    position: absolute;
    background: url("../../assets/image2/BG-3.png") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 120px;
  }
}
</style>
